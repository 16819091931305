<template>
  <div>
    <b-card
      no-body
    >
      <b-table
        ref="refListTable"
        :items="instancias"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        empty-text="Nenhum registro encontrado"
        striped
      >

        <template #cell(instancias)="data">
          <div class="text-nowrap">
            <b-form-checkbox
              v-model="data.detailsShowing"
              :disabled="data.item.instanciaOcupadasQtd == 0"
              @change="data.toggleDetails"
            >
              {{ data.detailsShowing ? 'Ocultar' : 'Ver' }}
            </b-form-checkbox>
          </div>
        </template>

        <template #cell(estrategia)="data">
          <div class="text-nowrap">
            <span>{{ data.value }}</span>
          </div>
        </template>

        <template #cell(instanciaOcupadasQtd)="data">
          <div class="text-nowrap">
            <span>{{ data.value }} de {{ data.item.instancias.length }}</span>
          </div>
        </template>

        <template #cell(capitalAlocadoPerc)="data">
          <div class="text-nowrap">
            <span>{{ data.value }} %</span>
          </div>
        </template>

        <template #cell(aprovaOperacao)="data">
          <div class="text-nowrap">
            <span>{{ data.value? 'Sim': 'Não' }}</span>
          </div>
        </template>

        <template #cell(acao)="item">
          <b-dropdown
            variant="link"
            no-caret
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item
              @click.stop="viewOperacao(item.item.id, item.item.idOperacao)"
            >
              <feather-icon icon="SettingsIcon" />
              <span class="align-middle ml-50">Ajustes</span>
            </b-dropdown-item>

          </b-dropdown>

        </template>

        <template #row-details="data">
          <b-card v-if="data.item.instanciaOcupadasQtd > 0">
            <b-table
              :items="data.item.instancias"
              responsive
              :fields="tableColumnsInstancia"
              primary-key="id"
              show-empty
              empty-text="Nenhuma instância encontrada"
              bordered
            >
              <template #cell(alavancagem)="item">
                <div class="text-nowrap">
                  <span>{{ item.value }}</span>
                </div>
              </template>

              <template #cell(dataEntrada)="item">
                <div class="text-nowrap">
                  <span>{{ formatDateTimeUTC0(item.value) }} </span>
                </div>
              </template>

              <template #cell(ativos)="item">
                <div
                  v-if="item.value"
                  class="text-nowrap"
                >
                  <b-avatar
                    size="sm"
                    :src="getIcon(item.item.coin)"
                  />
                  <span class="mr-1">
                    {{ item.value }}
                  </span>
                </div>
              </template>

              <template #cell(direcao)="item">
                <div
                  v-if="item.value"
                  class="text-nowrap"
                >
                  {{ item.value }}
                  <b-badge variant="light-warning">
                    {{ item.item.alavancagem }}x
                  </b-badge>
                </div>
              </template>

              <template #cell(lucro)="item">
                <template v-if="tickers[item.item.ativos]">
                  <div class="text-nowrap">
                    <span :class="getClass(item.item)">
                      {{ getLucroPercentual(item.item) }}
                      <small>%</small>
                    </span>
                  </div>
                </template>
                <template v-else-if="item.item.ativos">
                  <div class="text-center">
                    <b-spinner small />
                  </div>
                </template>
              </template>

              <template #cell(acao)="item">
                <b-dropdown
                  v-if="item.item.idOperacao"
                  variant="link"
                  no-caret
                >
                  <template #button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="align-middle text-body"
                    />
                  </template>

                  <b-dropdown-item
                    @click.stop="viewOperacao(item.item.id, item.item.idOperacao)"
                  >
                    <feather-icon icon="EyeIcon" />
                    <span class="align-middle ml-50">Detalhes</span>
                  </b-dropdown-item>

                  <b-dropdown-item
                    @click.stop="confirmarEncerrarAgora(item.item.idOperacao)"
                  >
                    <feather-icon icon="TargetIcon" />
                    <span class="align-middle ml-50">Encerrar</span>
                  </b-dropdown-item>

                </b-dropdown>

              </template>
            </b-table>
          </b-card>
        </template>

      </b-table>
    </b-card>
  </div>
</template>

<script>

import {
  BCard, BTable, BAvatar, BBadge, BSpinner, BDropdown, BDropdownItem, BFormCheckbox,
} from 'bootstrap-vue'
import store from '@/store'
import { formatDateTimeUTC0 } from '@/utils/filter'
import utilsMixin from '@/mixins/utilsMixin'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import operadorStoreModule from './operadorStoreModule'

const MONITOR_OPERADOR_ESTRATEGIA_INSTANCIA_FUT_STORE_MODULE_NAME = 'monitor-operador-estrategia-instancia-fut'

export default {

  components: {
    BCard,
    BTable,
    BAvatar,
    BBadge,
    BSpinner,
    BDropdown,
    BDropdownItem,
    BFormCheckbox,
  },

  mixins: [utilsMixin],

  data() {
    return {
      formatDateTimeUTC0,
      tableColumns: [
        { key: 'instancias', label: 'Instâncias' },
        { key: 'id', label: 'ID' },
        { key: 'estrategia', label: 'Estratégia' },
        { key: 'instanciaOcupadasQtd', label: 'Operações abertas' },
        { key: 'capitalAlocadoPerc', label: 'Alocação' },
        { key: 'tempoGrafico', label: 'Tempo gráfico' },
        { key: 'aprovaOperacao', label: 'Requer aprovação' },
      ],
      tableColumnsInstancia: [
        { key: 'id', label: 'ID' },
        { key: 'dataEntrada', label: 'Data entrada (UTC+0)' },
        { key: 'ativos', label: 'Ativo' },
        { key: 'direcao', label: 'Direção' },
        { key: 'lucro', label: 'Lucro (%)' },
        { key: 'acao', label: 'Ações' },
      ],
      instancias: [],
      operadorData: null,
      totalCount: 0,
    }
  },

  computed: {
    tickers() {
      return this.$store.state.binanceFut.tickers
    },
  },

  watch: {
  },

  created() {
    if (!store.hasModule(MONITOR_OPERADOR_ESTRATEGIA_INSTANCIA_FUT_STORE_MODULE_NAME)) store.registerModule(MONITOR_OPERADOR_ESTRATEGIA_INSTANCIA_FUT_STORE_MODULE_NAME, operadorStoreModule)
    this.pollfetchList()
  },

  beforeDestroy() {
    if (store.hasModule(MONITOR_OPERADOR_ESTRATEGIA_INSTANCIA_FUT_STORE_MODULE_NAME)) store.unregisterModule(MONITOR_OPERADOR_ESTRATEGIA_INSTANCIA_FUT_STORE_MODULE_NAME)
  },

  methods: {

    rowClass(item, type) {
      if (!item || type !== 'row') { return 'none' }
      if (item.posicionado) {
        const lucro = this.getLucroPercentual(item)
        if (!Number.isNaN(lucro) && lucro >= 0) {
          // eslint-disable-next-line consistent-return
          return 'table-success'
        }
        // eslint-disable-next-line consistent-return
        return 'table-danger'
      }
      return 'none'
    },

    getLucroPercentual(data) {
      if (!this.tickers[data.ativos] || !this.tickers[data.ativos].price) {
        return ''
      }

      const tickersPrice = parseFloat(this.tickers[data.ativos].price)
      const precoEntrada = parseFloat(data.precoEntrada)
      // eslint-disable-next-line prefer-destructuring
      const lote = data.lote

      if (data.direcao === 'LONG') {
        const lucro = (tickersPrice - precoEntrada) * lote
        const saldoTotal = parseFloat(data.saldoEntrada)
        const lucroPercentual = (lucro / saldoTotal) * 100
        return this.toFixed(lucroPercentual, 2)
      }

      if (data.direcao === 'SHORT') {
        const lucro = -1 * (tickersPrice - precoEntrada) * lote
        const saldoTotal = parseFloat(data.saldoEntrada)
        const lucroPercentual = (lucro / saldoTotal) * 100
        return this.toFixed(lucroPercentual, 2)
      }

      return ''
    },

    getClass(data) {
      if (!this.tickers[data.ativos] || !this.tickers[data.ativos].price) {
        return ''
      }
      const tickersPrice = parseFloat(this.tickers[data.ativos].price)
      const precoEntrada = parseFloat(data.precoEntrada)

      if (data.direcao === 'LONG') {
        return tickersPrice >= precoEntrada ? 'text-success' : 'text-danger'
      }
      if (data.direcao === 'SHORT') {
        return precoEntrada >= tickersPrice ? 'text-success' : 'text-danger'
      }
      return ''
    },

    async getOperadorContexto() {
      const response = await this.$store.dispatch('monitor-operador-estrategia-instancia-fut/getOperadorContexto')
      return response.data
    },

    async pollfetchList() {
      this.operadorData = await this.getOperadorContexto() // Await the Promise
      this.getOperadorEstrategiaInstancias(this.operadorData)
      const self = this
      this.polling = setInterval(() => {
        self.getOperadorEstrategiaInstancias(self.operadorData)
      }, 30000)
    },

    getOperadorEstrategiaInstancias(operadorData) {
      this.$store.dispatch('monitor-operador-estrategia-instancia-fut/getOperadorEstrategiaInstancias', operadorData)
        .then(response => {
          this.instancias = response.data
          this.totalCount = this.instancias.length

          // Define detailsShowing com base na presença de instâncias posicionadas
          this.instancias.forEach(instancia => {
            // eslint-disable-next-line no-nested-ternary
            instancia.instancias.sort((a, b) => (a.idOperacao === null ? 1 : b.idOperacao === null ? -1 : 0))
            this.$set(instancia, '_showDetails', instancia.instanciaOcupadasQtd > 0)
          })
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Erro ao recuperar a lista.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },

    // Encerrar Agora
    confirmarEncerrarAgora(id) {
      this.$swal({
        title: 'Tem certeza que deseja encerrar a posição agora?',
        text: 'Sua posição será encerrada pelo preço de mercado',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, pode encerrar!',
        cancelButtonText: 'Abortar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.encerrarAgora(id, this.operadorData)
        }
      })
    },

    encerrarAgora(id, operadorData) {
      this.$store.dispatch('monitor-operador-estrategia-instancia-fut/encerrarOperacao', { id, operadorData })
        .then(() => {
          this.$swal({
            icon: 'success',
            title: 'Encerrando operação!',
            text: 'Sua operação está sendo encerrada',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        })
    },

    viewOperacao(id, idOperacao) {
      this.$router.push(`/futures/monitor/operador-estrategia/instancia/${id}/operacao/${idOperacao}`).catch(() => {})
    },
  },

}
</script>
